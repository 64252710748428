import React, { FC, useContext, useState, createContext } from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useRouter } from "next/router";
import { FINANCE, PROCESSING } from "../config/routes";
import { Theme } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SUCCESS_GREEN = {
  main: "#589853",
};
const WARNING_ORANGE = {
  main: "#EDA340",
};
const ERROR_RED = {
  main: "#E8605A",
};

const NON_MODULE_COLORS = {
  success: SUCCESS_GREEN,
  warning: WARNING_ORANGE,
  error: ERROR_RED,
};

const PDS_GREEN = {
  light: "#4d8484",
  main: "#284e4f",
  dark: "#2a3435",
  contrastText: "#FFF",
};
const TEAL = {
  main: "#8fdcd2",
  contrastText: "#5c5c5c",
};

// Create a theme instance.
export const lightModeGreen = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: PDS_GREEN,
      secondary: TEAL,
    },
  })
);

export const darkModeGreen = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: TEAL,
      secondary: PDS_GREEN,
    },
  })
);

const PURPLE = {
  light: "#775faa",
  main: "#49357a",
  dark: "#1b0e4d",
  contrastText: "#FFF",
};
const LIGHT_PURPLE = {
  light: "#fbe1ff",
  main: "#c7aff7",
  dark: "#957fc4",
  contrastText: "#5c5c5c",
};

export const lightModePurple = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: PURPLE,
      secondary: LIGHT_PURPLE,
      ...NON_MODULE_COLORS,
    },
  })
);

export const darkModePurple = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: LIGHT_PURPLE,
      secondary: PURPLE,
      ...NON_MODULE_COLORS,
    },
  })
);

const BLUE = {
  light: "#5472d3",
  main: "#0d47a1",
  dark: "#002171",
  contrastText: "#FFF",
};
const LIGHT_BLUE = {
  light: "#b5ffff",
  main: "#80d6ff",
  dark: "#49a5cc",
  contrastText: "#5c5c5c",
};

export const lightModeBlue = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: BLUE,
      secondary: LIGHT_BLUE,
      ...NON_MODULE_COLORS,
    },
  })
);

export const darkModeBlue = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: LIGHT_BLUE,
      secondary: BLUE,
      ...NON_MODULE_COLORS,
    },
    
  })
);

const getFromStorage = (key: string): string | null | undefined => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }
};
const setToStorage = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(key, value);
  }
};

interface ThemeContextVariables {
  dark: boolean;
}

interface ThemeContextInterface extends ThemeContextVariables {
  setDark: (dark: boolean) => void;
  dark: boolean;
  theme: Theme;
  green: string;
  purple: string;
  blue: string;
}

const ThemeContext = createContext<ThemeContextInterface>({
  dark: false,
  setDark: () => {},
  theme: lightModeGreen,
  green: PDS_GREEN.main,
  purple: PURPLE.main,
  blue: BLUE.main,
});

export const ThemeContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [ctx, setCtx] = useState<ThemeContextVariables>({
    dark: getFromStorage("dark") === "true",
  });
  const router = useRouter();
  const color = router.pathname.startsWith(PROCESSING)
    ? "purple"
    : router.pathname.startsWith(FINANCE)
    ? "blue"
    : "green";
  const theme =
    color === "purple"
      ? ctx.dark
        ? darkModePurple
        : lightModePurple
      : color === "blue"
      ? ctx.dark
        ? darkModeBlue
        : lightModeBlue
      : ctx.dark
      ? darkModeGreen
      : lightModeGreen;

  const green = darkModeGreen.palette.primary.main;
  const purple = darkModePurple.palette.primary.main;
  const blue = darkModeBlue.palette.primary.main;
  return (
    <ThemeContext.Provider
      value={{
        ...ctx,
        theme,
        green,
        purple,
        blue,
        setDark: (dark: boolean) => {
          setToStorage("dark", dark ? "true" : "false");
          // Bandaid for weird nextjs rendering bug -
          // ThemeProvider only updates light/dark theme changes once
          // Any subsequent calls to setCtx here will cause e.g.
          // dark/light icon to toggle, *BUT THE THEME DOESNT CHANGE*
          // and it seems like a hard refresh is the fastest fix for now
          if (window) window.location.href = window.location.href;
          else setCtx({ ...ctx, dark });
        },
      }}
    >
      <ThemeProvider theme={theme}>{ctx && children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export const useScreenSMDown = () =>
  useMediaQuery(useTheme().breakpoints.down("sm"));
export const useScreenMDDown = () =>
  useMediaQuery(useTheme().breakpoints.down("md"));
export const useScreenLgDown = () =>
  useMediaQuery(useTheme().breakpoints.down("lg"));
